<template>
  <div class="practice-page-two-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      :numBers="numBers"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
    <div class="practice-page-box">
      <SceneSwitchThumbnails
        v-if="sceneSwitchShow"
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>

      <!-- 主体判断部分 -->
      <div class="practice-content">
        <!-- 失败和成功背景 -->
        <div
          class="checkGif"
          :class="{ checkGifWrong: !checkGif }"
          v-checkgif="checkGif"
          v-if="isShowCheckGif"
        ></div>
        <template v-for="(item, index) in practiceList">
          <div
            class="practice-page-content-item"
            v-if="numBers <= (index + 1) * 2 && numBers > index * 2"
            :key="index + 'choooseImg'"
          >
            <div
              class="img-content-item"
              v-for="(ele, indexSon) in item.imgList"
              :key="indexSon + 'img'"
              @click.stop="handleCheck(ele, indexSon)"
              @mouseenter="hoverIndex = indexSon"
              @mouseleave="hoverIndex = null"
              :class="{ imgHover: hoverIndex === indexSon }"
            >
              <div class="img-area">
                <div class="bg-img">
                  <img :src="ele.bgImg" alt="" class="target-img" />
                </div>
                <!-- 图片 -->

                <!-- 字和拼音 -->
                <div
                  class="targetSymbolTip"
                  :class="{
                    cardSelected: curentClickIndex === indexSon,
                  }"
                >
                  <div class="lesson-foseImgFree card-selection-text">
                    <span class="pinyin font-pinyin-medium">{{
                      item.pinyin
                    }}</span>
                    <span class="font-hanzi-medium">{{ item.hanzi }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
export default {
  props: {
    practiceList: {
      type: Array,
      require: true,
      default: () => [],
    },
    buttonList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  data() {
    return {
      checkGif: true,
      isShowCheckGif: false,
      lock: false,
      numBers: 1,
      curentClickIndex: null,
      hoverIndex: false,

      sceneSwitchShow: true,
      isLastStep: false,

      updateNumbersValue: 0,
      oldCurrentIndex: 0,
      handleCheckValue: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },

        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 6,
            numBerValueAfterClick: null,
          },
        ],
      },

      selectSocketInfo: {},
      menuSocketInfo: {},
    };
  },
  mounted() {
    this.$bus.$on("clickGameSideBtn", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
    this.$bus.$on("clickChooseImg", ({ item, index }) => {
      this.handleCheck(item, index, true);
    });
  },
  watch: {
    menuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2000501,
          data: { value },
          text: "测试PracticePage组件内sideButtonClick事件socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      },
      deep: true,
    },
    selectSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2000502,
          data: { value },
          text: "测试PracticePage组件内selectClick事件socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.$bus.$off("clickGameSideBtn");
    this.$bus.$off("clickChooseImg");
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.menuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.numBers = index;
      this.curentClickIndex = null;
      this.lock = false;
      if (this.numBers < this.practiceList.length * 2) {
        this.isLastStep = false;
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleCheck(item, index, isFromSocket = false) {
      if (!isFromSocket) {
        this.selectSocketInfo = {
          item,
          index,
          value: Math.random()
        };
      }
      if (this.lock) {
        return;
      }
      this.curentClickIndex = index;
      this.imgHover = null;
      if (item.isRight) {
        this.numBers++;
        this.lock = true;
        this.isShowCheckGif = true;
        this.checkGif = true;
        setTimeout(() => {
          this.isShowCheckGif = false;
        }, 1500);
        if (this.numBers == 2 * this.practiceList.length) {
          startConfetti();
          playCorrectSound();
        } else {
          playCorrectSound(true, false);
        }

        setTimeout(() => {
          if (this.numBers >= 2 * this.practiceList.length) {
            this.isLastStep = true;
          }
        }, 1500);
      } else {
        this.isShowCheckGif = true;
        this.checkGif = false;
        playCorrectSound(false);
        setTimeout(() => {
          this.isShowCheckGif = false;
        }, 1500);
      }
    },
    updateNumbers() {
      this.curentClickIndex = null;
    },
  },
};
</script>
<style scoped lang="scss">
.practice-page-two-container {
  .practice-page-box {
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/img/03-Backgrounds/background-practice.svg");
    background-color: #f7f4e0;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .customlesson-breakthrough-part {
      position: absolute;
      top: 0%;
      right: 10%;
      height: 40px;
      display: flex;
      padding: 10px 30px;
      background-color: #f4d737;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      border-bottom: 6px solid #d1b726;
      z-index: 1001;
      .demo {
        display: flex;
        .customlesson-breakthrough-part-book {
          margin-left: 5px;
          img {
            width: 25px;
            height: 35px;
          }
        }
      }
    }
    .targetSymbolTip {
      position: absolute;
      top: -75px;
      right: 10%;
      // width: 29%;
      // height: 27%;
      padding: 10px;
      border-radius: 20px;

      z-index: 20;
      // border-radius: 15px;
      background-color: #224e96;
      display: flex;
      justify-content: center;
      align-items: center;
      .lesson-foseImgFree {
        padding: 10px 20px;
        border-radius: 20px;
        // width: 60%;
        // height: 41%;
        z-index: 1;
        // border-radius: 15px;
        background-color: #cd4c3f;
        border-bottom: 8px solid #a53d32;
        border-top: 8px solid #d87065;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding: 12% 10%;
        span {
          color: #fff;
          font-size: 32px;
          line-height: 32px;
          @media screen and (max-width: 1500px) {
            // laptop
            font-size: 30px;
          }
          @media screen and (max-width: 1300px) {
            // ipad
            font-size: 20px;
          }
        }
      }
    }
    .practice-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .practice-page-content-item {
      // display: none;
      width: 80%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .img-content-item {
        display: flex;
        width: 40%;
        // height: 60%;

        .img-area {
          // height: 100%;
          width: 100%;
          position: relative;
          cursor: pointer;
          .result-bg-img {
            position: absolute;
            bottom: 29px;
            left: 0;
            border: 0;
            img {
              width: 50%;
            }
          }
          .bg-img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border-radius: 41px;

            .target-img {
              border-radius: 40px;
              width: 100%;
              border-bottom: 30px solid #cd4c3f;
            }
          }
        }
      }
    }
    .imgHover {
      img {

        border-color: #f4d737 !important;
      }
    }
    .cardSelected {
      background: #f4d737;
      opacity: 1 !important;
    }
  }
}
</style>
